import React, { Component } from 'react';
import { connect } from 'react-redux';
import NiubizButtonForm from './niubiz/NiubizButtonForm';
import NiubizForm from './niubiz/NiubizForm';
import { getTokenByNiubiz } from '../../../actions/bookingActions';

class NiubizFrame extends Component {

    constructor(props) {
        super(props);
        this.state = {
            transaction: this.props.bookingReducer.transaction,
            isCampaign: true,
            openForm: false
        }
    }

    getObjectByToken(transaction) {

        const purchaseNumber = transaction.paymentInfo.orderNumber;
        const amount = transaction.paymentInfo.amount;
        const email = transaction.contactInfo.email;
        const docId = transaction.contactInfo.document.docId;

        return {
            purchaseNumber,
            amount,
            email,
            docId
        }
    }

    onClick = e => {
        const objectTocken = this.getObjectByToken(this.state.transaction);
        this.props.getTokenByNiubiz(objectTocken);
        this.setState({ openForm: true });
    }

    renderPending(transaction) {
        return (
            <div>
                {
                    this.state.openForm ?
                        (this.state.isCampaign ?
                            ( this.props.bookingReducer.niubizToken !== null ?
                                <NiubizForm
                                    transaction={transaction}
                                    niubizToken={this.props.bookingReducer.niubizToken}
                                    isFullConfiguration={false}
                                /> : "CARGANDO FORMULARIO..."
                            ) 
                            :
                            <NiubizButtonForm
                                transaction={transaction}
                            />
                        )
                        :
                        (<div className="row">
                            <div className="form.group col-md-5 m-auto pt-3 pb-3">
                                <button
                                    className="btn btn-primary iubiz-button blue shadow-md rounded-sm w-full"
                                    //style={{ backgroundColor: "#06ffd1", fontWeight: "bold", color: "white" }}
                                    onClick={this.onClick}>
                                    Realizar pago</button>
                            </div>
                        </div>)
                }
            </div>
        )
    }

    render() {
        const { transaction } = this.state;
        const status = transaction.status;
        const statusTest = "PP";
        return (
            <div>
                {statusTest === "PP" ?
                    this.renderPending(transaction)
                    : this.renderNotPending(transaction)}
            </div>
        )
    }
}

const mapStateToProps = ({ bookingReducer }) => {
    return {
        bookingReducer
    }
}

export default connect(mapStateToProps, { getTokenByNiubiz })(NiubizFrame);