import React, { Component } from 'react';
import enginesUtils from "../../utils/enginesUtils";
import { getCheckoutData  } from '../../actions/ckeckoutActions';
import { bookInsurance } from '../../actions/bookingActions'
import { connect } from 'react-redux';
import Loading from '../commons/Loading';
import PassengersForm from './PassengersForm';
import InsuranceInformation from './InsuranceInformation';
import ContactForm from './ContactForm';
import Error from '../commons/Error';
import LoadingProgress from '../commons/LoadingProgress';
import BookingProgress from '../commons/BookingProgress';
import Navbar from '../commons/Navbar';
import PaymentOptions from './PaymentOptions';
import PaymentContent from './PaymentContent';
import { CASH } from '../../constants/paymentOptionsIDConstants';
import paymentsWithCreditCard from '../../constants/paymentOptionsWithCreditCardConstants';
import InsuranceDetails from './InsuranceDetails';
import InsuranceRules from './InsuranceRules';
import Loading2 from '../commons/Loading2';
import InsurancePriceInformation from './InsurancePriceInformation';
import PriceInformation from './PriceInformation';
import '../../assets/stylesheets/Insurances.css';
import profiles, { B2B_PERU_AGENTES_VACACIONES } from '../../constants/profilesConstants';

class Reservation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pathArray: null,
            name: null,
            lastName: null,
            isSubmitting: false,
            paymentOptionSelected: CASH,
            isFormValid: true,
            selectedModal : {
                id: null,
                openModal: false
            },
            sendToValidate: false,
            commissionAgency: 0,
            isOpenInsurancePriceInformation: false,
            emitToAgencyChecked: false,
            accountIdAgencyToEmit: null
        }
    }

    componentDidMount() {
        const pathArray = enginesUtils.getPathArrayFromUrl(window.location.pathname);
        //const sessionId = this.props.match.params.id;
        const sessionId = pathArray[1];
        this.props.getCheckoutData(sessionId);
    }

    onChange = e => {
        let value = e.target.value;
        this.setState({
            [e.target.name]: value
        })
    }

    handleSubmit = e => {
        const isValid = enginesUtils.isFormValid(paymentsWithCreditCard, this.state);
        this.setState(
            {   isSubmitting: isValid.valid,
                isFormValid: isValid.valid,
                sendToValidate: true
            }, 
            () => {
                if(isValid.valid) {
                    this.props.bookInsurance (
                        {
                            searchParams: this.props.data.searchParams,
                            insurance: this.props.data.insurance,
                            sessionID: this.props.data.sessionID,
                            bookForm: this.props.bookFormReducer,
                            paymentOptionSelected: this.state.paymentOptionSelected,
                            accountIdAgencyToEmit: this.state.accountIdAgencyToEmit,
                            emitToAgencyChecked: this.state.emitToAgencyChecked
                        },
                        this.props.history
                        ) 
                } else {
                        window.Swal.fire({
                            title: 'Advertencia',
                            text: `${isValid.error}`,
                            icon: 'warning',
                            showCancelButton: false,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'OK'
                        });
                        window.scrollTo(0, 0);
                }
            }
        );
    }

    onChangePaymentOption = (option) => {
        this.setState({ paymentOptionSelected: option })
    }

    
    openModal = () => {
        const productId = this.props.data.insurance.product.idProduct;
        const selectedModal = {
            id: productId,
            openModal: true
        }
        this.setState({ selectedModal : selectedModal });
    }

    closeModal = () => {
        const selectedModal = {
            id: null,
            openModal: false
        }
        this.setState({ selectedModal: selectedModal })
    }

    render() {
        return (

            <div className='container-fluid pl-0 pr-0' style={{backgroundColor: "#ECECEC"}}>
                {this.props.isLoading ? <Loading2 title={"Recuperando información de su producto seleccionado"} /> :
                <div>
                    <LoadingProgress activeStep={1} />
                    <div className='container'>
                        <BookingProgress activeStep={1} />
                    </div>
                    {this.props.bookingReducer.error ? 
                        <Error
                            title={this.props.bookingReducer.error.title}
                            message={this.props.bookingReducer.error.message}
                            origin={window.location.origin}
                            onClose={() => {
                                window.location.href  = this.props.data.pathSearch
                            }}
                        />
                        : null
                    }
                    <div className='row container ml-auto mr-auto'>
                        <h1 className='col-md-12 mb-1'>Datos de los pasajeros</h1>
                        <div className="col-md-8">
                            <PassengersForm
                                insurance={this.props.data.insurance}
                                searchParams={this.props.data.searchParams}
                                sendToValidate={this.state.sendToValidate}
                            />
                            <ContactForm 
                                sendToValidate={this.state.sendToValidate}
                            />
                            <PaymentOptions 
                                onChangePaymentOption={this.onChangePaymentOption}
                            />
                            <PaymentContent
                                optionSelected={this.state.paymentOptionSelected}
                            />

                            {profiles.profilesToShowAccountId.includes(this.props.data.engine.profile.id) ?
                                <div className='bg-white mt-3 mb-3 p-3'>
                                <div className="">
                                    <div className="checkbox">
                                        <label className="control control--checkbox">
                                            {this.props.data.engine.profile.id === B2B_PERU_AGENTES_VACACIONES ? 
                                                "Ingresar accountId del cliente: " : "¿Va a emitir para alguna agencia? "}
                                            <input
                                                className='cursor-pointer'
                                                type="checkbox"
                                                style={{marginLeft: "20px"}}
                                                checked={this.state.emitToAgencyChecked}
                                                onChange={e =>
                                                    this.setState({ emitToAgencyChecked: e.target.checked })
                                                }
                                            />
                                            <div className="control__indicator" />
                                        </label>
                                    </div>
                                </div>
                                
                                    {this.state.emitToAgencyChecked ?
                                    <div className='col-md-4 col-sm-12 pl-0'>
                                      <label for="validationLastName" className="mt-2 mb-2 font-weight-bold">Account ID</label>
                                        <input
                                            name='accountIdAgencyToEmit'
                                            onChange={this.onChange}
                                            className="form-control"
                                            value={this.state.accountIdAgencyToEmit}
                                            required
                                        />
                                        </div> : null
                                    }
                                </div> : null
                            }
                            
                            
                            <InsuranceRules engine={this.props.data.engine} />
                            <div className="row mt-3 mb-5">
                                <div className="col">
                                    <button
                                        className={`BookingButton p-3 w-100
                                    ${(this.state.isSubmitting) ? "disabled" : ""} `}
                                        onClick={
                                            !this.state.isSubmitting
                                                ? this.handleSubmit
                                                : () => false
                                        }
                                    >{this.state.isSubmitting ? "Reservando...": "Reservar"}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4 mt-3 mb-3'>
                            <InsuranceDetails 
                                product={this.props.data.insurance.product}
                                selectedModal={this.state.selectedModal}
                                closeModal={this.closeModal}
                                searchParams={this.props.data.searchParams}
                                commissionAgency={this.state.commissionAgency}
                            />
                                
                                <div className='mt-3 mb-3'>
                                    <PriceInformation 
                                        insurance={this.props.data.insurance}
                                        searchParams={this.props.data.searchParams}
                                    />
                                </div>
                                <div className='btn btn-sm float-right mb-2'
                                    onClick={() => {
                                        this.setState({
                                            isOpenInsurancePriceInformation: !this.state.isOpenInsurancePriceInformation
                                        })
                                    }}
                                >
                                    <i className="fas fa-bars" />
                                </div>   
                            {this.state.isOpenInsurancePriceInformation ? <InsurancePriceInformation /> : null}
                        </div>
                    </div>
                </div>
                }
            </div>

        )
    }
}

const mapStateToProps = ({ checkoutReducer,  bookFormReducer, bookingReducer}) => {
    return {
        bookFormReducer,
        isLoading: checkoutReducer.loading,
        data: checkoutReducer.data,
        bookingReducer
    }
}

export default connect(mapStateToProps, { getCheckoutData, bookInsurance })(Reservation);