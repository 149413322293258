import React, { Component } from "react";
import { B2B_PERU_AGENTES_VACACIONES } from "../../constants/profilesConstants";
import { COSTAMAR_COM_ENGINE } from "../../constants/engineCodesConstants";

class InsuranceRules extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isChecked: false,
            privacyPoliciesChecked: false,
            termsAndConditionsChecked: false,
            authorizedPersonalDataChecked: false,
            showValidateCheckedAuthorizedPersonalData: false, 
            profilesValidByAuthorizedPersonalDataChecked: [
                B2B_PERU_AGENTES_VACACIONES
            ],
            enginesValidByAuthorizedPersonalDataChecked: [
                COSTAMAR_COM_ENGINE
            ],
        }
    }

    componentDidMount() {
        const showValidateCheckedAuthorizedPersonalData = this.hasEngineCodeOrProfileToValidatePersonalData();
        this.setState({
            showValidateCheckedAuthorizedPersonalData: showValidateCheckedAuthorizedPersonalData
        });
    }

    hasEngineCodeOrProfileToValidatePersonalData = () => {
        const hasEngineCodeToAuthorized = this.state.enginesValidByAuthorizedPersonalDataChecked.includes(this.props.engine.code);
        if (hasEngineCodeToAuthorized) {
          return hasEngineCodeToAuthorized;
        }
        return this.state.profilesValidByAuthorizedPersonalDataChecked.includes(this.props.engine.profile.id);
      }

    render() {
        return (
            <div className="row mt-3 mb-3">
                <div className="col">
                    <div className="checkbox">
                        <label className="control control--checkbox" style={{ marginRight: "10px" }}>
                        </label>
                        <input type="hidden" 
                            value={ (!this.state.showValidateCheckedAuthorizedPersonalData ||  this.state.authorizedPersonalDataChecked) 
                                && this.state.privacyPoliciesChecked
                                && this.state.termsAndConditionsChecked} 
                            id="ir_f" 
                        />
                        <div className="d-flex">
                            <input
                                type="checkbox"
                                className="cursor-pointer"
                                defaultChecked={this.state.privacyPoliciesChecked}
                                onChange={e => {
                                    const checked = e.target.checked;
                                    this.setState({
                                        privacyPoliciesChecked: checked
                                    });
                                }}
                            />
                            <p className="ml-3 mt-3">
                                Leo, entiendo y acepto las
                                <a
                                    style={{ color: "rgb(0, 80, 171)", fontWeight: "400" }}
                                    className="ml-1">
                                </a> <a style={{ color: "rgb(0, 80, 171)", fontWeight: "400" }} href="https://costamar.com.pe/politicas-privacidad" target="_blank">políticas de privacidad</a>
                                .
                            </p>
                            <div className="control__indicator"></div>
                        </div>
                        <div className="d-flex">
                            <input
                                type="checkbox"
                                className="cursor-pointer"
                                defaultChecked={this.state.termsAndConditionsChecked}
                                onChange={e => {
                                    const checked = e.target.checked;
                                    this.setState({
                                        termsAndConditionsChecked: checked
                                    });
                                }}
                            />
                            <p className="ml-3 mt-3">
                                Leo, entiendo y acepto 
                                <a style={{ color: "rgb(0, 80, 171)", fontWeight: "400" }} href="https://costamar.com.pe/terminos-condiciones" target="_blank"> los términos y condiciones de uso</a>
                                .
                            </p>
                            <div className="control__indicator"></div>
                        </div>
                        {this.state.showValidateCheckedAuthorizedPersonalData ?
                            (<div className="d-flex">
                                <input
                                    type="checkbox"
                                    className="cursor-pointer"
                                    defaultChecked={this.state.authorizedPersonalDataChecked}
                                    onChange={e => {
                                        const checked = e.target.checked;
                                        this.setState({
                                            authorizedPersonalDataChecked: checked
                                        });
                                    }}
                                />
                                <p className="ml-3 mt-3">
                                    Autorizo a Costamar a utilizar mis datos personales para enviarme información y publicidad, de acuerdo con lo establecido en la
                                    <a style={{ color: "rgb(0, 80, 171)", fontWeight: "400" }} href="https://costamar.com.pe/politicas-privacidad" target="_blank"> política de privacidad</a>
                                    .
                                </p>
                                <div className="control__indicator"></div>
                            </div>) :null
                        }

                    </div>
                </div>
            </div>
        )
    }
}

export default InsuranceRules;