import React, { Component } from 'react';
import moment from "moment";
import { connect } from 'react-redux';
import { sendParamsToSearch } from '../../actions/insurancesActions';
import DatePicker from "react-datepicker";
import destinations from '../../data/destinations.json';
import enginesUtils from '../../utils/enginesUtils';
//import SweetAlert from 'sweetalert2-react';
import {
    FaRegCalendarAlt,
    FaPlaneArrival
} from 'react-icons/fa';


import "react-datepicker/dist/react-datepicker.css";
import "../../assets/stylesheets/Engines.css";
import Button from 'react-bootstrap/Button';
import { AGES_PARAM_INDEX, DEFAULT_TERMINAL_ID, DESTINATION_PARAM_INDEX, END_DATE_PARAM_INDEX, START_DATE_PARAM_INDEX } from '../../constants/searchParamConstants';

class InsurancesEngine extends Component {

    constructor() {
        super();
        this.state = {
            origin: "PERU",
            destination: null,
            destinationName: null,
            startDate: null,
            endDate: null,
            dateRange: {
                startDate: null,
                endDate: null
            },
            paxQuantity: 1,
            agesInputArray: [],
            isFormValid: false,
            openPassengerModal: false,
            activeDestinationInput: false,
            hasCotizationTry: false
        }
    }

    componentDidMount() {


        if (this.props.isRequote) {
            //recotization state inicial

            this.setStatePrev(this.props.statePrev)

        }
    }

    setStatePrev(statePrev) {
        let destinationName = enginesUtils.getDestinationNameByValue(this.props.statePrev[1]);

        this.setState({
            origin: statePrev[0],
            destination: statePrev[1],
            destinationName,
            startDate: moment(statePrev[2]).valueOf(),
            endDate: moment(statePrev[3]).valueOf()
        })
    }

    handleStartDateChange(date) {
        let dateFinally = moment(date).format("YYYY-MM-DD");
        this.setState({ startDate: date })
    }

    handleEndDateChange(date) {
        let dateFinally = moment(date).format("YYYY-MM-DD");
        this.setState({ endDate: date })
    }

    onChange = e => {
        let value = e.target.value;

        this.setState({
            [e.target.name]: value
        })
    }

    openModal = () => {
        this.setState({ openPassengerModal: true })
    }

    onClickModal = () => {
        const newOpenPassengerModal= !this.state.openPassengerModal;
        this.setState({ openPassengerModal:  newOpenPassengerModal });
    }

    closeModal = () => {
        this.setState({ openPassengerModal: false })
    }

    onChangeAgesArray = e => {
        const { agesInputArray } = this.state;
        //aqui lo agregamos al array agesInputArray con push
        if (agesInputArray.length === 0) {
            agesInputArray.push(e.target.value);
            this.setState({ agesInputArray: agesInputArray })
        } else {
            if (agesInputArray[e.target.name]) {
                agesInputArray[e.target.name] = e.target.value;
            } else {
                agesInputArray.splice(e.target.name, 0, e.target.value)
            }
            this.setState({ agesInputArray: agesInputArray })
        }
    }

    showErrorMessageWhenIsNotCompleted(index) {
        let errorMessage = '';

        if (this.state.hasCotizationTry) {
            const { arrayParamsInvalid } = enginesUtils.hasCompleteFieldsByState(this.state);
            arrayParamsInvalid.forEach(pi => {
                if (pi.index === index) {
                    errorMessage = pi.errorMessage
                }
            });
        }

        return errorMessage;
    }

    onSubmit = e => {
        e.preventDefault()

        const { hasCompleteFields, pathParams } = enginesUtils.hasCompleteFieldsByState(this.state);
        if (hasCompleteFields) {
            let pathStringArray = `${pathParams.join("/")}`;
            const finalURI = window.location.origin + "/" + pathStringArray + "?terminalId=" + DEFAULT_TERMINAL_ID;
            window.location.href = finalURI;
        } else {
            this.setState({
                hasCotizationTry: true
            });
        }


    }



    render() {
        const { paxQuantity } = this.state;
        let textTitle = "Cotiza tu asistencia de viaje";
        let textButton = "Cotizar";
        if (this.props.isRequote) {
            textTitle = "Recotiza tu viaje";
            textButton = "Recotizar";
        }
        let opacityButtonClassname = ''
        if(!enginesUtils.hasCompleteFieldsByState(this.state).hasCompleteFields) {
            opacityButtonClassname = 'InsurancesEngine_button_opacity';
        }

        return (
            <div className='col-12 col-md-9 m-auto p-auto mt-3 mb-3'>
                <legend className="box-title mb-0 p-1 InsurancesEngine_legend col-md-4">
                    {textTitle}</legend>
                <form className='p-3 bg-white font-form' style={{ borderRadius: "0px 10px 10px 10px", }}>
                    <div className='row mt-3 mb-3' style={{justifyContent: "center"}}>

                        <div className='form-group col-12 col-md-3 mb-0'>
                            <div className='d-flex'>
                                <div className='insurances_engine_icon'><FaPlaneArrival /></div>
                                <select className="form-control select EngineInput"
                                    id="destination"
                                    name='destination'
                                    onChange={this.onChange}
                                >

                                    {destinations.map(dest => (
                                        <option className='destinationOption' key={dest.value}
                                            style={{cursor: "pointer"}}
                                            value={dest.value}>
                                            {dest.name}
                                        </option>
                                    ))

                                    }
                                </select>
                            </div>   
                            <p className='errorMessageEngine'>{this.showErrorMessageWhenIsNotCompleted(DESTINATION_PARAM_INDEX)}</p>
                        </div>
                        <div className='form-group col-12 col-md-2 mb-0'>
                            <div className='d-flex'>
                                <div className='insurances_engine_icon'><FaRegCalendarAlt /></div>
                                    <DatePicker
                                        selected={this.state.startDate}
                                        onChange={(date) => this.handleStartDateChange(date)}
                                        minDate={new Date()}
                                        className="form-control select EngineInput"
                                        placeholderText="Fecha salida"
                                        style={{ cursor: "grab" }}
                                        value={this.state.startDate ?
                                                moment(this.state.startDate).format('DD/MM/YYYY') : 'Fecha salida'}
                                    />     
                            </div>
                            <p className='errorMessageEngine'>{this.showErrorMessageWhenIsNotCompleted(START_DATE_PARAM_INDEX)}</p>
                        </div>
                        <div className='form-group col-12 col-md-2 mb-0'>
                            <div className='d-flex'>
                                <div className='insurances_engine_icon'><FaRegCalendarAlt /></div>
                                <DatePicker
                                    selected={this.state.endDate}
                                    onChange={(date) => this.handleEndDateChange(date)}
                                    minDate={this.state.startDate}
                                    className="form-control select EngineInput"
                                    placeholderText="Fecha regreso"
                                    value={this.state.endDate ?
                                        moment(this.state.endDate).format('DD/MM/YYYY') : 'Fecha regreso'}
                                />
                            </div>
                            <p className='errorMessageEngine'>{this.showErrorMessageWhenIsNotCompleted(END_DATE_PARAM_INDEX)}</p>    
                        </div>
                        <div className='form-group col-12 col-md-2 mb-0'>
                            <div type="button" className='form-control EngineInput'
                                onClick={() => {
                                    //this.openModal();
                                    this.onClickModal()
                                }}
                                value={`${paxQuantity} ${paxQuantity > 1 ? 'pasajeros' : 'pasajero'}`}
                            >{paxQuantity} <i className="fa fa-user insurances_engine_icon" aria-hidden="true"></i>
                            </div>
                            <p className='errorMessageEngine'>{this.showErrorMessageWhenIsNotCompleted(AGES_PARAM_INDEX)}</p>
                            <div className="card ml-auto mt-3 p-3 AgesInputForm" 
                                style={{ 
                                    display: `${this.state.openPassengerModal ? 'block': 'none'}` 
                                    }}>
                                <div className='card-title mb-0'>
                                    <div className='form-group col-md-10'>
                                        <div className='d-flex'>
                                            <i
                                                className={`fa fa-minus-circle btn InsurancesEngine_passengers_button  ${paxQuantity <= 1 ? 'disabled' : ''}`}
                                                onClick={() => {
                                                    if (paxQuantity > 1) {
                                                        const agesInputArray = this.state.agesInputArray.pop();
                                                        this.setState({ paxQuantity: paxQuantity - 1 })
                                                    }
                                                }}
                                                aria-hidden="true">
                                            </i>
                                            <input name='paxQuantity' className='form-control mr-3 ml-3'
                                                style={{width: "120px"}}
                                                value={`${paxQuantity} ${paxQuantity > 1 ? `pasajeros` : 'pasajero'}`} />
                                                <i aria-hidden="true"
                                                    className={`fa fa-plus-circle btn InsurancesEngine_passengers_button ${paxQuantity >= 8 ? 'disabled' : ''}`}
                                                    onClick={() => {
                                                        if (paxQuantity < 8) {
                                                            this.setState({ paxQuantity: paxQuantity + 1 })
                                                        }
                                                    }
                                                    }
                                                ></i>
                                                <div className='ml-3'>
                                                    <Button variant="primary"
                                                        onClick={this.closeModal}
                                                        className='InsurancesEngine_passengers_ok_button'
                                                    >
                                                        OK
                                                    </Button>
                                                </div>                                                
                                        </div>
                                    </div>
                                </div>
                                <div className='card-body pt-1'>
                                    <div className='row ml-0'>
                                        <div className='form-group col-12'>
                                            <div className='row'>
                                                {[...Array(this.state.paxQuantity)].map((e, index) => {
                                                    return (
                                                        <div className='d-flex mt-2'>
                                                            <label className='mr-3'>Edad del pasajero {index + 1}</label>
                                                            <input key={index}
                                                                className="form-control col-2 mr-3"
                                                                name={index}
                                                                onChange={this.onChangeAgesArray}
                                                                value={this.state.agesInputArray.length > 0 ?
                                                                    this.state.agesInputArray[index] : ""}
                                                            />
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='form-group col-12 col-md-2 text-center mb-0'>
                            <button
                                className={`btn InsurancesEngine_button w-100 quote-button mb-2 d-flex ${opacityButtonClassname}`}
                                value="cotizar" 
                                onClick={this.onSubmit}
                            >
                                <i className="fa fa-search" aria-hidden="true"></i>
                                <p>Cotizar</p>
                            </button>
                        </div>


                    </div>

                </form>
            </div >
        )
    }
}


const mapStateToProps = ({ insurancesReducer }) => {
    return {
        searchParams: insurancesReducer.searchParams ? insurancesReducer.searchParams : null
    }
}

export default connect(mapStateToProps, { sendParamsToSearch })(InsurancesEngine);