import React, { Component } from "react";
import { connect } from "react-redux";
import { fillPassengerForm } from "../../actions/bookFormActions";
import validator from '../../utils/validator';
import enginesUtils from '../../utils/enginesUtils';
import { END_DATE_PARAM_INDEX, START_DATE_PARAM_INDEX } from "../../constants/searchParamConstants";

class PassengerInput extends Component {

    constructor(props) {
        super(props);
        this.state = {
            index: props.index,
            firstName: "",
            lastName: "",
            invalidFields: {},
            completedFields: [],
            isAgeValid: false,
            day: "",
            month: "",
            year: "",
            phoneNumber: "",
            gender: "M",
            isValid: false,
            email: "",
            documentNumber: "",
            documentType: "NI",
            countryCode: "PE"
        }

        this.validate = this.validate.bind(this);
        this.validateField = this.validateField.bind(this);
    }

    //siempre se llama antes del render y sirva para saber si continuar con el render o no
    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.isValid && this.state === nextState) {
            return false;
        }
        return true;
    }

    //siempre se llama después del render
    componentDidUpdate(prevProps, prevState) {
        if (this.state.isValid === true) {
            this.props.fillPassengerForm({
                passenger: Object.assign(this.state, {})
            });
        }
    }

    validate = (field = null) => {
        let invalidFields = {};
        let completedFields = this.state.completedFields;

        if (!field) {
        } else {
            let fieldToValidate = field;
            const { message } = this.validateField(fieldToValidate);
            if (typeof message !== 'undefined') { //hay error
                invalidFields[fieldToValidate] = { message };
            } else {
                if (completedFields.indexOf(fieldToValidate) === -1) {
                    completedFields.push(fieldToValidate);
                }
            }

            let isValid = false;
            const requiredFields = this.getRequiredFields();

            if (Object.keys(invalidFields).length === 0) {
                isValid = this.isFieldsCompleted(requiredFields, completedFields);
            }
            this.setState({ invalidFields, completedFields, isValid });
        }

    }

    isFieldsCompleted(requiredFields, completedFields) {
        return (
            requiredFields.length <= completedFields.length &&
            requiredFields.every(function (v) {
                return completedFields.indexOf(v) !== -1;
            })
        );
    }

    validateField(field) {
        let error = {};
        let validation;

        switch (field) {
            case 'firstName':
                validation = validator.isPresent(this.state.firstName);
                if (!validation.isValid) {
                    error = validation.error;
                }
                break;
            case 'lastName':
                validation = validator.isPresent(this.state.lastName);
                if (!validation.isValid) {
                    error = validation.error;
                }
                break;
            case 'phoneNumber':
                validation = validator.isPresent(this.state.phoneNumber);
                if (!validation.isValid) {
                    error = validation.error;
                }
                break;
            case 'email':
                validation = validator.isPresent(this.state.email);
                if (!validation.isValid) {
                    error = validation.error;
                }
                break;
            case 'documentNumber':
                validation = validator.isPresent(this.state.documentNumber);
                if (!validation.isValid) {
                    error = validation.error;
                }
                break;
            default:
                break;
        }
        return error;
    }

    getRequiredFields() {
        const defaultRequiredFields = [
            "firstName",
            "lastName",
            "phoneNumber",
            "email",
            "documentNumber"
        ]
        return defaultRequiredFields;
    }

    onChange = (e) => {
        const field = e.target.name;
        const value = e.target.value;
        this.setState({ [field]: value }, () =>
            this.validate(field)
        )
    }

    render() {
        const years = enginesUtils.getYears(this.props.searchParams[START_DATE_PARAM_INDEX]);
        const days = enginesUtils.getDays();
        const documentTypes = enginesUtils.getDocumentTypes();
        const age = this.props.age;
        const genders = enginesUtils.getGenders();
        const countries = enginesUtils.getCountries();
        let ageValid = null;
        const months = enginesUtils.getNamesOfMonths();
        if (this.state.day !== "" && this.state.month !== "" && this.state.year !== "") {
            ageValid = enginesUtils.isValidAge(this.state.year + "-" + this.state.month + "-" + this.state.day, age);
        }
        return (
            <div className="mt-3 mb-3 bg-white p-3 border rounded-sm">
                <div className="d-flex">
                    <p className="mt-auto mb-auto" style={{ textTransform: "uppercase", fontWeight: "bold" }}>Datos del pasajero {this.props.index + 1}</p>
                    {<strong style={{ backgroundColor: "rgb(0, 81, 145)", color: "white", paddingLeft: "10px", paddingRight: "10px", paddingTop: "5px", paddingBottom: "5px", marginLeft: "10px", borderRadius: "5px" }}>Edad: {age}</strong>}
                </div>
                {years.length > 0 ?
                    <div className="row">
                        <div className="form-group col-md-6">
                            <label for="validationFirstName" className="mt-2 mb-2 form-label">Nombre</label>
                            <input
                                name='firstName'
                                onChange={this.onChange}
                                className="form-control"
                                //placeholder='Ingrese su nombre'
                                value={this.state.firstName}
                                id="validationFirstName"
                                required
                            />
                            <div className="valid-feedback">
                                Nombre ingresado correctamente
                            </div>
                            <div className="invalid-feedback">
                                Por favor, completa el nombre
                            </div>
                        </div>
                        <div className="form-group col-md-6">
                            <label for="validationLastName" className="mt-2 mb-2">Apellidos</label>
                            <input
                                name='lastName'
                                onChange={this.onChange}
                                className="form-control"
                                //placeholder='Ingrese su nombre'
                                value={this.state.lastName}
                                id="validationLastName"
                                required
                            />
                            <div className="valid-feedback">
                                Apellidos ingresados correctamente
                            </div>
                            <div className="invalid-feedback">
                                Por favor, completa el apellido correctamente
                            </div>
                        </div>
                        <div className="col-md-8">
                            <label className="mt-2 mb-2">Fecha de nacimiento</label>
                            <div className="row">
                                <div className="col">
                                    <select
                                        name="day"
                                        className="form-control cursor-pointer"
                                        onChange={e => {
                                            const { value } = e.target;
                                            this.setState({ day: value })
                                        }}
                                        required
                                    >
                                        <option>Día</option>
                                        {days.map(day => (
                                            <option key={day} value={day}>
                                                {day}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col">
                                    <select
                                        name="month"
                                        className="form-control cursor-pointer"
                                        onChange={e => {
                                            const { value } = e.target;
                                            this.setState({ month: value })
                                        }}
                                    >
                                        <option>Mes</option>
                                        {months.map(month => (
                                            <option key={month.value} value={month.value}>
                                                {month.displayName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col">
                                    <select
                                        name="year"
                                        className="form-control cursor-pointer"
                                        onChange={e => {
                                            const { value } = e.target;
                                            this.setState({ year: value })
                                        }}
                                    >
                                        <option>Año</option>
                                        {years.map(year => (
                                            <option key={year} value={year}>
                                                {year}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="col">
                                <label className="mt-2 mb-2">Género</label>
                                <select
                                    name="gender"
                                    className="form-control cursor-pointer"
                                    onChange={e => {
                                        const { value } = e.target;
                                        this.setState({ gender: value })
                                    }}
                                >
                                    <option>Género</option>
                                    {genders.map(gender => (
                                        <option key={gender.name} value={gender.value}>
                                            {gender.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="form-group col-md-12 mt-3 mb-0">
                            <div className="row">
                                <div className="col-md-4 col">
                                    <label className="mt-2 mb-2">Lugar de nacimiento</label>
                                    <select
                                        name="countryCode"
                                        className="form-control cursor-pointer"
                                        onChange={e => {
                                            const { value } = e.target;
                                            this.setState({ countryCode: value })
                                        }}
                                    >
                                        {countries.map(country => (
                                            <option key={country.code} value={country.code}>
                                                {country.value}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-md-8 col">
                                    <div className="row">
                                        <div className="col">
                                            <label className="mt-2 mb-2">Tipo de documento</label>
                                            <select
                                                name="documentType"
                                                className="form-control cursor-pointer"
                                                onChange={e => {
                                                    const { value } = e.target;
                                                    this.setState({ documentType: value })
                                                }}
                                            >
                                                {documentTypes.map(document => (
                                                    <option key={document.value} value={document.value}>
                                                        {document.display}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col">
                                            <label htmlFor="inputPhoneNumber4" className="mt-2 mb-2">Número de documento</label>
                                            <input
                                                name='documentNumber'
                                                onChange={this.onChange}
                                                className="form-control"
                                                //placeholder='Ingrese su nombre'
                                                value={this.state.documentNumber}
                                                type="number"
                                                required
                                            />
                                            <div className="valid-feedback">
                                                Correo ingresado correctamente
                                            </div>
                                            <div class="invalid-feedback">
                                                Por favor, completa el número de documento
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group col-md-6">
                            <label for="validationPhoneNumber" className="mt-2 mb-2">Teléfono</label>
                            <input
                                name='phoneNumber'
                                onChange={this.onChange}
                                className="form-control"
                                //placeholder='Ingrese su nombre'
                                value={this.state.phoneNumber}
                                id="validationPhoneNumber"
                                type="number"
                                required
                            />
                            <div className="valid-feedback">
                                Numero telefónico ingresado correctamente
                            </div>
                            <div class="invalid-feedback">
                                Por favor, completa el número telefónico
                            </div>
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="inputPhoneNumber4" className="mt-2 mb-2">Correo</label>
                            <input
                                name='email'
                                onChange={this.onChange}
                                className="form-control"
                                //placeholder='Ingrese su nombre'
                                value={this.state.email}
                                required
                            />
                            <div className="valid-feedback">
                                Correo ingresado correctamente
                            </div>
                            <div class="invalid-feedback">
                                Por favor, completa el correo
                            </div>
                        </div>
                    </div> : null
                }
                {ageValid !== null ?
                    (<div>{ageValid === true ? <div className="alert alert-success">Fecha de nacimiento válido</div> : <div className="alert alert-danger">Fecha de nacimiento inválido</div>}</div>)
                    : null}
                <input type="hidden" key={this.state.index} value={this.state.isValid && ageValid} className="tr_f" />

            </div>
        )
    }
}


function mapStateToProps({ bookFormReducer }) {
    return {
        bookFormReducer
    };
}

export default connect(null, { fillPassengerForm })(PassengerInput);