import moment from 'moment';
import destinations from '../data/destinations.json';
import { AGES_PARAM_INDEX, DESTINATION_PARAM_INDEX, END_DATE_PARAM_INDEX, ORIGIN_PARAM_INDEX, START_DATE_PARAM_INDEX } from '../constants/searchParamConstants';

const functions = {

    getDestinationNameByValue(value) {
        let destinationName = "";
        destinations.forEach(d => {
            if (d.value === value) {
                destinationName = d.name;
            }
        });
        return destinationName;
    },

    getDestinationValueByDestinationIndex(destinationIndex) {
        let destinationValue = "";
        destinations.forEach(d => {
            if(d.index === parseInt(destinationIndex)) {
                destinationValue = d.value;
            }
        });
        return destinationValue;
    },

    getDestinationIndexByValue(destinationValue) {
        let destinationIndex = 0;
        destinations.forEach(d => {
            if(d.value === destinationValue) {
                destinationIndex = d.index;
            }
        })
        return destinationIndex;
    },

    getPathArrayFromUrl(path) {
        const pathSubstring = path.substring(1);
        const pathArray = pathSubstring.split("/");
        return pathArray;
    },

    getErrorMessagesByParamIndex(index) {
        let errorMessage = '';
        switch (index) {
            case ORIGIN_PARAM_INDEX:
                errorMessage = 'Por favor, seleccionar el campo de origen';
                return errorMessage;
            case DESTINATION_PARAM_INDEX:
                errorMessage = 'Por favor, seleccionar el campo de destino';
                return errorMessage;
            case START_DATE_PARAM_INDEX:
                errorMessage = 'Por favor, seleccionar fecha de salida';
                return errorMessage;
            case END_DATE_PARAM_INDEX:
                errorMessage = 'Por favor, seleccionar fecha de retorno';
                return errorMessage;
            case AGES_PARAM_INDEX:
                errorMessage = 'Por favor, ingresar la edad o edades de los pasajeros';
                return errorMessage;
            default:
                return 'Por favor, seleccionar el campo de destino';
        }
    },

    hasCompleteFieldsByState(state) {
        let isCompleteFields = true;
        let arrayParamsInvalid = [];
        const pathParams = [];
        const {
            origin,
            destination,
            startDate,
            endDate,
            paxQuantity,
            agesInputArray
        } = state;

        const destinationIndex = this.getDestinationIndexByValue(destination);

        pathParams.push(`${origin}`);
        pathParams.push(`${destination && destination !== "" ? destination.replace('/', " "): ""}`);
        pathParams.push(`${destinationIndex}`);
        pathParams.push(`${moment(startDate).format("YYYY-MM-DD")}`);
        pathParams.push(`${moment(endDate).format("YYYY-MM-DD")}`);
        pathParams.push(`${agesInputArray}`);
        pathParams.forEach((param, index) => {
            if (param === "null" || param === "Invalid date" || param === "") {
                const errorMessage =  this.getErrorMessagesByParamIndex(index);
                arrayParamsInvalid.push({
                    errorMessage : errorMessage,
                    param: param,
                    index: index
                });
                
            }
        })
        if (arrayParamsInvalid.length > 0) {
            isCompleteFields = false;
        }
        isCompleteFields = isCompleteFields && paxQuantity === agesInputArray.length;
        return {
            hasCompleteFields: isCompleteFields,
            pathParams: pathParams,
            arrayParamsInvalid: arrayParamsInvalid
        };
    },

    calculateElapsedDays (startDate, endDate) {
        let fechaInicio = new Date(startDate).getTime();
        let fechaFin = new Date(endDate).getTime();

        let diff = fechaFin - fechaInicio;

        let days = diff / (1000 * 60 * 60 * 24);

        return days + 1;
    },

    getYears(returnDate) {
        const years = [];
        let maxBirthdateForPassenger = moment(returnDate);
        let maximunYear = maxBirthdateForPassenger.year();
        for (let index = 0; index < 100; index++) {
            years.push(maximunYear);
            maximunYear -= 1;
        }
        return years;
    },

    getGenders() {
        const genders = [
            {value: "Masculino", name: "Masculino"},
            {value: "Femenino", name: "Femenino"}
        ]
        return genders;
    },

    getMonths() {
        const months = [];
        for (let index = 0; index < 12; index++) {
            if (index <= 8) {
                months.push(`0${index + 1}`)
            } else {
                months.push(index + 1);
            }

        }
        return months;
    },

    getNamesOfMonths() {
        const namesOfMonths =
            [
              {value: "01" , displayName: "Enero"},
              {value: "02", displayName: "Febrero"},
              {value: "03", displayName: "Marzo"},
              {value: "04", displayName: "Abril"},
              {value: "05", displayName: "Mayo"},
              {value: "06" , displayName: "Junio"},
              {value: "07", displayName: "Julio"},
              {value: "08", displayName: "Agosto"},
              {value: "09", displayName: "Setiembre"},
              {value: "10", displayName: "Octubre"},
              {value: "11", displayName: "Noviembre"},
              {value: "12", displayName: "Diciembre"}
            ]
        return namesOfMonths;
    },

    getDays() {
        const days = [];
        for (let index = 0; index < 31; index++) {
            if(index <=8) {
                days.push(`0${index + 1}`)
            } else {
                days.push(index + 1);
            }
        }
        return days;
    },

    getDocumentTypes() {
        const documentTypes = [
            {value: "NI", display: "DNI"},
            {value: "PP", display: "Pasaporte"},
            {value: "CE", display: "Carné de extranjería"}
        ];
        return documentTypes;
    },

    getCountries() {
        return [
            {code: "PE/Perú", value: "Perú"},
            {code: "US/Estados Unidos", value: "Estados Unidos"},
            {code: "EC/Ecuador", value: "Ecuador"},
            {code: "CO/Colombia", value: "Colombia"},
            {code: "MX/México", value: "México"},
            {code: "DO/República Dominicana", value: "República Dominicana"},
            {code: "AF/Afganistán", value: "Afganistán"},
            {code: "AL/Albania", value: "Albania"},
            {code: "DE/Alemania", value: "Alemania"},
            {code: "AD/Andorra", value: "Andorra"},
            {code: "AO/Angola", value: "Angola"},
            {code: "AI/Anguila", value: "Anguila"},
            {code: "AQ/Antártida", value: "Antártida"},
            {code: "AG/Antigua y Barbuda", value: "Antigua y Barbuda"},
            {code: "AN/Antillas Neerlandesas", value: "Antillas Neerlandesas"},
            {code: "SA/Arabia Saudí", value: "Arabia Saudí"},
            {code: "DZ/Argelia", value: "Argelia"},
            {code: "AR/Argentina", value: "Argentina"},
            {code: "AM/Armenia", value: "Armenia"},
            {code: "AW/Aruba", value: "Aruba"},
            {code: "AU/Australia", value: "Australia"},
            {code: "AT/Austria", value: "Austria"},
            {code: "AZ/Azerbaiyán", value: "Azerbaiyán"},
            {code: "BS/Bahamas", value: "Bahamas"},
            {code: "BH/Bahréin", value: "Bahréin"},
            {code: "BD/Bangladesh", value: "Bangladesh"},
            {code: "BB/Barbados", value: "Barbados"},
            {code: "BE/Bélgica", value: "Bélgica"},
            {code: "BZ/Belice", value: "Belice"},
            {code: "BJ/Benín", value: "Benín"},
            {code: "BM/Bermudas", value: "Bermudas"},
            {code: "BY/Bielorrusia", value: "Bielorrusia"},
            {code: "BO/Bolivia", value: "Bolivia"},
            {code: "BA/Bosnia-Herzegovina", value: "Bosnia-Herzegovina"},
            {code: "BW/Botsuana", value: "Botsuana"},
            {code: "BR/Brasil", value: "Brasil"},
            {code: "BN/Brunéi", value: "Brunéi"},
            {code: "BG/Bulgaria", value: "Bulgaria"},
            {code: "BF/Burkina Faso", value: "Burkina Faso"},
            {code: "BI/Burundi", value: "Burundi"},
            {code: "BT/Bután", value: "Bután"},
            {code: "CV/Cabo Verde", value: "Cabo Verde"},
            {code: "KH/Camboya", value: "Camboya"},
            {code: "CM/Camerún", value: "Camerún"},
            {code: "CA/Canadá", value: "Canadá"},
            {code: "EA/Ceuta y Melilla", value: "Ceuta y Melilla"},
            {code: "TD/Chad", value: "Chad"},
            {code: "CL/Chile", value: "Chile"},
            {code: "CN/China", value: "China"},
            {code: "CY/Chipre", value: "Chipre"},
            {code: "VA/Ciudad del Vaticano", value: "Ciudad del Vaticano"},
            
            {code: "KM/Comoras", value: "Comoras"},
            {code: "CG/Congo - Brazzaville", value: "Congo - Brazzaville"},
            {code: "KP/Corea del Norte", value: "Corea del Norte"},
            {code: "KR/Corea del Sur", value: "Corea del Sur"},
            {code: "CI/Costa de Marfil", value: "Costa de Marfil"},
            {code: "CR/Costa Rica", value: "Costa Rica"},
            {code: "HR/Croacia", value: "Croacia"},
            {code: "CU/Cuba", value: "Cuba"},
            {code: "DG/Diego García", value: "Diego García"},
            {code: "DK/Dinamarca", value: "Dinamarca"},
            {code: "DM/Dominica", value: "Dominica"},
            
            {code: "EG/Egipto", value: "Egipto"},
            {code: "SV/El Salvador", value: "El Salvador"},
            {code: "AE/Emiratos Árabes Unidos", value: "Emiratos Árabes Unidos"},
            {code: "ER/Eritrea", value: "Eritrea"},
            {code: "SK/Eslovaquia", value: "Eslovaquia"},
            {code: "SI/Eslovenia", value: "Eslovenia"},
            {code: "ES/España", value: "España"},
            
            {code: "EE/Estonia", value: "Estonia"},
            {code: "ET/Etiopía", value: "Etiopía"},
            {code: "PH/Filipinas", value: "Filipinas"},
            {code: "FI/Finlandia", value: "Finlandia"},
            {code: "FJ/Fiyi", value: "Fiyi"},
            {code: "FR/Francia", value: "Francia"},
            {code: "GA/Gabón", value: "Gabón"},
            {code: "GM/Gambia", value: "Gambia"},
            {code: "GE/Georgia", value: "Georgia"},
            {code: "GH/Ghana", value: "Ghana"},
            {code: "GI/Gibraltar", value: "Gibraltar"},
            {code: "GD/Granada", value: "Granada"},
            {code: "GR/Grecia", value: "Grecia"},
            {code: "GL/Groenlandia", value: "Groenlandia"},
            {code: "GP/Guadalupe", value: "Guadalupe"},
            {code: "GU/Guam", value: "Guam"},
            {code: "GT/Guatemala", value: "Guatemala"},
            {code: "GF/Guayana Francesa", value: "Guayana Francesa"},
            {code: "GG/Guernsey", value: "Guernsey"},
            {code: "GN/Guinea", value: "Guinea"},
            {code: "GQ/Guinea Ecuatorial", value: "Guinea Ecuatorial"},
            {code: "GW/Guinea-Bissau", value: "Guinea-Bissau"},
            {code: "GY/Guyana", value: "Guyana"},
            {code: "HT/Haití", value: "Haití"},
            {code: "HN/Honduras", value: "Honduras"},
            {code: "HU/Hungría", value: "Hungría"},
            {code: "IN/India", value: "India"},
            {code: "ID/Indonesia", value: "Indonesia"},
            {code: "IR/Irán", value: "Irán"},
            {code: "IQ/Iraq", value: "Iraq"},
            {code: "IE/Irlanda", value: "Irlanda"},
            {code: "BV/Isla Bouvet", value: "Isla Bouvet"},
            {code: "CX/Isla Christmas", value: "Isla Christmas"},
            {code: "CP/Isla Clipperton", value: "Isla Clipperton"},
            {code: "AC/Isla de la Ascensión", value: "Isla de la Ascensión"},
            {code: "IM/Isla de Man", value: "Isla de Man"},
            {code: "NU/Isla Niue", value: "Isla Niue"},
            {code: "NF/Isla Norfolk", value: "Isla Norfolk"},
            {code: "IS/Islandia", value: "Islandia"},{code: "AX/Islas Åland", value: "Islas Åland"},
            {code: "KY/Islas Caimán", value: "Islas Caimán"},
            {code: "IC/Islas Canarias", value: "Islas Canarias"},
            {code: "CC/Islas Cocos", value: "Islas Cocos"},
            {code: "CK/Islas Cook", value: "Islas Cook"},
            {code: "FO/Islas Feroe", value: "Islas Feroe"},
            {code: "GS/Islas Georgia del Sur y Sandwich del Sur", value: "Islas Georgia del Sur y Sandwich del Sur"},
            {code: "HM/Islas Heard y McDonald", value: "Islas Heard y McDonald"},
            {code: "FK/Islas Malvinas", value: "Islas Malvinas"},
            {code: "MP/Islas Marianas del Norte", value: "Islas Marianas del Norte"},
            {code: "MH/Islas Marshall", value: "Islas Marshall"},
            {code: "UM/Islas menores alejadas de los Estados Unidos", value: "Islas menores alejadas de los Estados Unidos"},
            {code: "PN/Islas Pitcairn", value: "Islas Pitcairn"},
            {code: "SB/Islas Salomón", value: "Islas Salomón"},
            {code: "TC/Islas Turcas y Caicos", value: "Islas Turcas y Caicos"},
            {code: "VG/Islas Vírgenes Británicas", value: "Islas Vírgenes Británicas"},
            {code: "VI/Islas Vírgenes de los Estados Unidos", value: "Islas Vírgenes de los Estados Unidos"},
            {code: "IL/Israel", value: "Israel"},
            {code: "IT/Italia", value: "Italia"},
            {code: "JM/Jamaica", value: "Jamaica"},
            {code: "JP/Japón", value: "Japón"},
            {code: "JE/Jersey", value: "Jersey"},
            {code: "JO/Jordania", value: "Jordania"},
            {code: "KZ/Kazajistán", value: "Kazajistán"},
            {code: "KE/Kenia", value: "Kenia"},
            {code: "KG/Kirguistán", value: "Kirguistán"},
            {code: "KI/Kiribati", value: "Kiribati"},
            {code: "KW/Kuwait", value: "Kuwait"},
            {code: "LA/Laos", value: "Laos"},
            {code: "LS/Lesoto", value: "Lesoto"},
            {code: "LV/Letonia", value: "Letonia"},
            {code: "LB/Líbano", value: "Líbano"},
            {code: "LR/Liberia", value: "Liberia"},
            {code: "LY/Libia", value: "Libia"},
            {code: "LI/Liechtenstein", value: "Liechtenstein"},
            {code: "LT/Lituania", value: "Lituania"},
            {code: "LU/Luxemburgo", value: "Luxemburgo"},
            {code: "MK/Macedonia", value: "Macedonia"},
            {code: "MG/Madagascar", value: "Madagascar"},
            {code: "MY/Malasia", value: "Malasia"},
            {code: "MW/Malaui", value: "Malaui"},
            {code: "MV/Maldivas", value: "Maldivas"},
            {code: "ML/Mali", value: "Mali"},
            {code: "MT/Malta", value: "Malta"},
            {code: "MA/Marruecos", value: "Marruecos"},
            {code: "MQ/Martinica", value: "Martinica"},
            {code: "MU/Mauricio", value: "Mauricio"},
            {code: "MR/Mauritania", value: "Mauritania"},
            {code: "YT/Mayotte", value: "Mayotte"},
            
            {code: "FM/Micronesia", value: "Micronesia"},
            {code: "MD/Moldavia", value: "Moldavia"},
            {code: "MC/Mónaco", value: "Mónaco"},
            {code: "MN/Mongolia", value: "Mongolia"},
            {code: "ME/Montenegro", value: "Montenegro"},
            {code: "MS/Montserrat", value: "Montserrat"},
            {code: "MZ/Mozambique", value: "Mozambique"},
            {code: "MM/Myanmar [Birmania]", value: "Myanmar [Birmania]"},
            {code: "NA/Namibia", value: "Namibia"},
            {code: "NR/Nauru", value: "Nauru"},
            {code: "NP/Nepal", value: "Nepal"},
            {code: "NI/Nicaragua", value: "Nicaragua"},
            {code: "NE/Níger", value: "Níger"},
            {code: "NG/Nigeria", value: "Nigeria"},
            {code: "NO/Noruega", value: "Noruega"},
            {code: "NC/Nueva Caledonia", value: "Nueva Caledonia"},
            {code: "NZ/Nueva Zelanda", value: "Nueva Zelanda"},
            {code: "OM/Omán", value: "Omán"},
            {code: "NL/Países Bajos", value: "Países Bajos"},
            {code: "PK/Pakistán", value: "Pakistán"},
            {code: "PW/Palau", value: "Palau"},
            {code: "PA/Panamá", value: "Panamá"},
            {code: "PG/Papúa Nueva Guinea", value: "Papúa Nueva Guinea"},
            {code: "PY/Paraguay", value: "Paraguay"},
           
            {code: "PF/Polinesia Francesa", value: "Polinesia Francesa"},
            {code: "PL/Polonia", value: "Polonia"},
            {code: "PT/Portugal", value: "Portugal"},
            {code: "PR/Puerto Rico", value: "Puerto Rico"},
            {code: "QA/Qatar", value: "Qatar"},
            {code: "HK/Región Administrativa Especial de Hong Kong de la República Popular China", value: "Región Administrativa Especial de Hong Kong de la República Popular China"},
            {code: "MO/Región Administrativa Especial de Macao de la República Popular China", value: "Región Administrativa Especial de Macao de la República Popular China"},
            {code: "GB/Reino Unido", value: "Reino Unido"},
            {code: "CF/República Centroafricana", value: "República Centroafricana"},
            {code: "CZ/República Checa", value: "República Checa"},
            {code: "CD/República Democrática del Congo", value: "República Democrática del Congo"},
            
            {code: "RE/Reunión", value: "Reunión"},
            {code: "RW/Ruanda", value: "Ruanda"},
            {code: "RO/Rumanía", value: "Rumanía"},
            {code: "RU/Rusia", value: "Rusia"},
            {code: "EH/Sáhara Occidental", value: "Sáhara Occidental"},
            {code: "WS/Samoa", value: "Samoa"},
            {code: "AS/Samoa Americana", value: "Samoa Americana"},
            {code: "BL/San Bartolomé", value: "San Bartolomé"},
            {code: "KN/San Cristóbal y Nieves", value: "San Cristóbal y Nieves"},
            {code: "SM/San Marino", value: "San Marino"},
            {code: "MF/San Martín", value: "San Martín"},
            {code: "PM/San Pedro y Miquelón", value: "San Pedro y Miquelón"},
            {code: "VC/San Vicente y las Granadinas", value: "San Vicente y las Granadinas"},
            {code: "SH/Santa Elena", value: "Santa Elena"},
            {code: "LC/Santa Lucía", value: "Santa Lucía"},
            {code: "ST/Santo Tomé y Príncipe", value: "Santo Tomé y Príncipe"},
            {code: "SN/Senegal", value: "Senegal"},
            {code: "RS/Serbia", value: "Serbia"},
            {code: "CS/Serbia y Montenegro", value: "Serbia y Montenegro"},
            {code: "SC/Seychelles", value: "Seychelles"},
            {code: "SL/Sierra Leona", value: "Sierra Leona"},
            {code: "SG/Singapur", value: "Singapur"},
            {code: "SY/Siria", value: "Siria"},
            {code: "SO/Somalia", value: "Somalia"},
            {code: "LK/Sri Lanka", value: "Sri Lanka"},
            {code: "SZ/Suazilandia", value: "Suazilandia"},
            {code: "ZA/Sudáfrica", value: "Sudáfrica"},
            {code: "SD/Sudán", value: "Sudán"},
            {code: "SE/Suecia", value: "Suecia"},
            {code: "CH/Suiza", value: "Suiza"},
            {code: "SR/Surinam", value: "Surinam"},
            {code: "SJ/Svalbard y Jan Mayen", value: "Svalbard y Jan Mayen"},
            {code: "TH/Tailandia", value: "Tailandia"},
            {code: "TW/Taiwán", value: "Taiwán"},
            {code: "TZ/Tanzania", value: "Tanzania"},
            {code: "TJ/Tayikistán", value: "Tayikistán"},
            {code: "IO/Territorio Británico del Océano Índico", value: "Territorio Británico del Océano Índico"},
            {code: "QO/Territorios alejados de Oceanía", value: "Territorios alejados de Oceanía"},
            {code: "TF/Territorios Australes Franceses", value: "Territorios Australes Franceses"},
            {code: "PS/Territorios Palestinos", value: "Territorios Palestinos"},
            {code: "TL/Timor Oriental", value: "Timor Oriental"},
            {code: "TG/Togo", value: "Togo"},{code: "TK/Tokelau", value: "Tokelau"},
            {code: "TO/Tonga", value: "Tonga"},
            {code: "TT/Trinidad y Tobago", value: "Trinidad y Tobago"},
            {code: "TA/Tristán da Cunha", value: "Tristán da Cunha"},
            {code: "TN/Túnez", value: "Túnez"},
            {code: "TM/Turkmenistán", value: "Turkmenistán"},
            {code: "TR/Turquía", value: "Turquía"},
            {code: "TV/Tuvalu", value: "Tuvalu"},
            {code: "UA/Ucrania", value: "Ucrania"},
            {code: "UG/Uganda", value: "Uganda"},
            {code: "EU/Unión Europea", value: "Unión Europea"},
            {code: "UY/Uruguay", value: "Uruguay"},
            {code: "UZ/Uzbekistán", value: "Uzbekistán"},
            {code: "VU/Vanuatu", value: "Vanuatu"},
            {code: "VE/Venezuela", value: "Venezuela"},
            {code: "VN/Vietnam", value: "Vietnam"},
            {code: "WF/Wallis y Futuna", value: "Wallis y Futuna"},
            {code: "YE/Yemen", value: "Yemen"},
            {code: "DJ/Yibuti", value: "Yibuti"},
            {code: "ZM/Zambia", value: "Zambia"},
            {code: "ZW/Zimbabue", value: "Zimbabue"}
        ]
    },

    isValidAge(birthday, age) {
        let isValid = true
    
        let dateToday = moment();
        let dateBirthday = moment(birthday);
    
        let aniosDifference = dateToday.diff(dateBirthday, "years")
    
        if(aniosDifference-age !== 0) {
            isValid = false;
        }
        return isValid;
    
      },

    isFormValid(paymentsWithCreditCard = [], state) {
    let passengersFormValid;
    let creditCardFormValid = true;

    for (let travelerForm of document.getElementsByClassName("tr_f")) {
        passengersFormValid = JSON.parse(travelerForm.value);
        if (!passengersFormValid) {
            break;
        }
    }
    let contactFormValid = JSON.parse(document.getElementById("ct_f").value);
    let insuranceRulesValid = JSON.parse(document.getElementById("ir_f").value);
    if(paymentsWithCreditCard.payments.includes(state.paymentOptionSelected)) {
        creditCardFormValid = JSON.parse(document.getElementById("cd_f").value);
    }

    if(!passengersFormValid) {
        return {
            valid: false,
            error: 'Datos incompletos o erróneos en formulario de pasajeros'
        }
    }
    if(!contactFormValid) {
        return {
            valid: false,
            error: 'Datos incompletos o erróneos en formulario de contacto'
        }
    }
    if(!creditCardFormValid) {
        return {
            valid: false,
            error: 'Datos incompletos o erróneos en formulario de tarjeta'
        }
    }
    if(!insuranceRulesValid) {
        return {
            valid: false,
            error: 'Debe aceptar todas las condiciones de compra'
        }
    }

    return {
        valid: true,
        error: ""
    };
    },

    isValidAgeForCotization(agesArray) {
        let isValidAge = true;
        if(agesArray.length === 1) {
            if (parseInt(agesArray[0]) > 75) {
                isValidAge = false;
            }
        } else if(agesArray.length > 1) { 
            agesArray.split(",").forEach(age => {
                if (parseInt(age) > 75) {
                    isValidAge = false;
                }
            });
            return isValidAge;
        }
    },

    isValidAgeForEngineCotization(agesArray) {
        let isValidAge = true;
        if(agesArray.length > 0) {
            agesArray.forEach(age => {
                if (parseInt(age) > 75) {
                    isValidAge = false;
                }
            })
        }
        return isValidAge;
    },

    formatThousandAmount(amount) {
        const decimalSeparator = ",";
        let parseStringByAmount = parseFloat(amount).toFixed(0);
        const index = parseStringByAmount.length - 3;
        if(parseStringByAmount.length > 3) {
            return parseStringByAmount.slice(0, index).concat(decimalSeparator).concat(parseStringByAmount.slice(index));
        }
        return parseStringByAmount; 
    },

    getLabelPricePerPassenger(insurance, profilesToShowPassengerTotalPrice = []) {
        if(profilesToShowPassengerTotalPrice.includes(insurance.pos.profileId)) {
            return "Precio total por pasajero";
        }
        return "Precio neto por pasajero";
    },

    getAmountPricePerPassenger(insurance, profilesToShowPassengerTotalPrice = [], product, params) {
        if(profilesToShowPassengerTotalPrice.includes(insurance.pos.profileId)) {
            const startDate = params[START_DATE_PARAM_INDEX];
            const endDate = params[END_DATE_PARAM_INDEX];
            const coverageDays = this.calculateElapsedDays(startDate, endDate);
            return parseFloat(coverageDays * product.fareProduct.priceDayByPax).toFixed(2);
        }
        return  parseFloat(product.fareProduct.baseFareByPax).toFixed(2);
    }

}

export default functions;