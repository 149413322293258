import React, { Component } from 'react';
import { connect } from 'react-redux';
import logos from '../../../../assets/images/payment/niubiz/logos.png';
import logoCostamar from '../../../../assets/images/payment/niubiz/logo.png';

class NiubizForm extends Component {

    constructor() {
        super();
        this.state = {
            nombre: "",
            apellido: "",
            email: "",
            tokenAuthorized: true,
            isCardNumberValid: false,
            paySend: false
        }
    }

    componentDidMount() {
        this.loadForm()
    }  

    loadForm = () => {

        const  niubiz  = this.props.niubizToken;

        window.channel = niubiz.channel;
        window.amount = niubiz.amount;

        window.configuration = {

            sessionkey: niubiz.sessionToken,
            channel: niubiz.channel,
            merchantid: niubiz.merchantId,
            purchasenumber: niubiz.purchaseNumber,
            amount: niubiz.amount,
            callbackurl: '',
            language: 'es',
        };

        window.purchase = niubiz.purchaseNumber;
        window.payform.setConfiguration(window.configuration);
        //validacion para configurar

        const elementStyles = {
            base: {
                color: 'gray',
                fontWeight: 100,
                fontFamily: "'Ubuntu', sans-serif",
                fontSize: '16px',
                //fontSmoothing: 'antialiased',
                border: '2px solid #555555',
                borderRadius: '5px',
                letterSpacing: '10px',
                wordSpacing: '30px',
                padding: '43px 0px 50px 43px',
                width: '100%',
                placeholder: {
                    color: '#6c757d',
                },
                autofill: {
                    color: '#e39f48',
                },
            },
            invalid: {
                color: '#E25950',
                '::placeholder': {
                    color: '#FFCCA5',
                },
            },
        };

        window.cardNumber = window.payform.createElement('card-number', { style: elementStyles, placeholder: 'Número de tarjeta' }, 'txtNumeroTarjeta');
        window.cardExpiry = window.payform.createElement('card-expiry', { style: elementStyles, placeholder: 'MM/YYYY' }, 'txtFechaVencimiento');
        window.cardCvc = window.payform.createElement('card-cvc', { style: elementStyles, placeholder: 'CVC' }, 'txtCvv');

        let bin = "";
        let last = "";
        let cardNumber = "4474118355632240";
        //let cardNumber =
          //  this.props.paymentObject.paymentDetail.paymentCard.cardNumber;

        window.cardNumber.then(element => {
            element.on('bin', function (data) {
                /* Tú código aquí */
                bin = data
            })

            element.on('installments', function (data) {


                if (data != null) {

                    window.credito = true;
                    var cuotas = document.getElementById('cuotas');
                    cuotas.style.display = "block";

                    var select = document.createElement('select');
                    select.setAttribute("class", "form-control form-control-sm");
                    select.setAttribute("id", "selectCuotas");
                    let optionDefault = document.createElement('option');
                    optionDefault.value = optionDefault.textContent = "Sin cuotas";
                    select.appendChild(optionDefault);
                    data.forEach(function(item) {
                        let option = document.createElement('option');
                        option.value = option.textContent = item;
                        select.appendChild(option);
                    });
                    cuotas.appendChild(select);
                } else {
                    window.credito = false;
                    var cuotas = document.getElementById('selectCuotas');
                    if (cuotas !== undefined) {
                        cuotas.parentNode.removeChild(cuotas);
                    }
                }

            });

            element.on('lastFourDigits', (data) => {
                last = data;

                if (cardNumber.includes(bin) && cardNumber.includes(last)) {
                    this.setState({ isCardNumberValid: true })
                } else {
                    this.setState({ isCardNumberValid: false })
                }

            })

        })

    }

    onChange = (e) => {
        let value = e.target.value;
        this.setState({
            [e.target.name]: value
        })
    }

    pay = () => {
        this.state.isCardNumberValid ? this.payCardNumberValid() : this.payCardNumberNotValid();
    }

    payCardNumberValid = () => {
    }

    payCardNumberNotValid = () => {
        alert('Número de tarjeta no coincide con la ingresada anteriormente !')
    }

    renderForm(transaction) {
        return (
            <div className="main-content mt-5 pt-5">
                <div className="container">

                    <div className="row justify-content-md-center">
                        <div className="col-md-4">
                            <div className="card">

                                <div className='payment-logo'>
                                    <img 
                                        src={logoCostamar} 
                                        className="logo" 
                                        alt="logoCostamar" />
                                </div>

                                <p className='text-logo pt-3'>
                                    <label
                                        style={{ textAlign: "center" }}>
                                        <strong>Recuerda</strong> activar las
                                        <strong> compras por internet</strong>
                                        <br></br>con tu banco
                                    </label>
                                </p>

                                <div className="card-body">

                                    <div className='card space icon-relative mb-0'>
                                        <div id="txtNumeroTarjeta" name="txtNumeroTarjeta"
                                            onChange={this.onChange}
                                            className="form-control form-control-sm ncp-card inputNiubiz2">
                                        </div>
                                        <small id="msjNroTarjeta" className="form-text text-muted red-text m-0"></small>
                                    </div>

                                    <br />

                                    <div className="card-grp space">
                                        <div className="card-item icon-relative">
                                            <div id="txtFechaVencimiento" className="form-control form-control-sm inputNiubiz"></div>
                                            <i className="far fa-calendar-alt"></i>
                                        </div>
                                        <div className="card-item icon-relative d-flex">
                                            <div id="txtCvv" className="form-control form-control-sm inputNiubiz"></div>
                                            <div><i className="fas fa-lock"></i></div>
                                        </div>
                                    </div>

                                    <div className="card-grp space mt-4">
                                        <div className="card-item icon-relative">
                                            <input type="text"
                                                className="form-control form-control-sm inputNiubiz" placeholder="Nombre"
                                                name='nombre'
                                                onChange={this.onChange} />
                                            <i className="fas fa-user"></i>
                                        </div>
                                        <div className="card-item icon-relative">
                                            <input type="text" className="form-control form-control-sm inputNiubiz"
                                                placeholder="Apellido"
                                                name='apellido'
                                                onChange={this.onChange} />
                                            <i className="fas fa-user"></i>
                                        </div>

                                    </div>

                                    <div className="card space icon-relative">
                                        <input type="text" className="form-control form-control-sm inputNiubiz"
                                            placeholder="Correo electrónico"
                                            name='email'
                                            onChange={this.onChange} />
                                        <i className="fas fa-user"></i>
                                    </div>

                                    <div className="row mt-4">
                                        <div className="col-md-12" id="cuotas"
                                            style={{ display: "none" }}></div>
                                    </div>

                                    <div className="btnNiubiz mt-0"
                                        onClick={this.pay} id="btnProcesar">
                                        Pagar {transaction.paymentInfo.currency} {transaction.paymentInfo.amount}
                                    </div>

                                    <div className='row'>
                                        <div className='col-12'>
                                            <img src={logos} className="logos" alt="" />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {

        return(
            <div>{this.renderForm(this.props.transaction)}</div>
        )
    }
}

export default NiubizForm;