import React, { Component } from "react";
import { connect } from "react-redux";
import uuid from "react-uuid";
import PassengerInput from "./PassengerInput";
import { AGES_PARAM_INDEX } from "../../constants/searchParamConstants";

class PassengersForm extends Component {

    //https://reactjs.org/docs/react-component.html#shouldcomponentupdate

    constructor(props) {
        super(props);
        this.state = {
            wasValidate : false
        }
    }
    
    shouldComponentUpdate(nextProps, nextState) {
        if (!this.props.searchParams && nextProps.searchParams || ( !this.props.sendToValidate && nextProps.sendToValidate )) {
            return true;
        }
        return false;
    }
    
    render() {
        const ages = this.props.searchParams[AGES_PARAM_INDEX].split(",");
        return (
            <form className={`needs-validation`}  noValidate>
                {
                    ages.map( (age, index) => {
                        const key = uuid();
                        const props = {
                            key : key,
                            index : index,
                            age: age,
                            searchParams: this.props.searchParams
                        }
                        return <PassengerInput {...props} />
                    })
                }
            </form>
        )
    }
}

function mapStateToProps({ bookFormReducer }) {
    return {
        bookFormReducer
    };
}

export default connect( mapStateToProps, {})(PassengersForm);