import React, { Component } from "react";
import InsuranceModal from "./InsuranceModal";
import { AGES_PARAM_INDEX, DESTINATION_INDEX_PARAM_INDEX, DESTINATION_PARAM_INDEX, END_DATE_PARAM_INDEX, ORIGIN_PARAM_INDEX, START_DATE_PARAM_INDEX } from "../../constants/searchParamConstants";
import moment from "moment";
import enginesUtils from "../../utils/enginesUtils";
import { connect } from "react-redux";

class InsuranceDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedModal : {
                id: null,
                openModal: false
            }
        }
    }

    openModal = () => {
        const productId = this.props.product.idProduct;
        const selectedModal = {
            id: productId,
            openModal: true
        }
        this.setState({ selectedModal : selectedModal });
    }

    closeModal = () => {
        const selectedModal = {
            id: null,
            openModal: false
        }
        this.setState({ selectedModal: selectedModal })
    } 

    render() {
        const {product, searchParams }= this.props;
        const total = parseFloat(this.props.product.fareProduct.totalToPaid) + parseFloat(this.props.bookFormReducer.commissionAgency);

        return (
            <div className="card">
                <div className="card-body InsuranceDetails_title p-2 text-center">
                    <div className="card-title mb-0" style={{ fontWeight: "bold" }}>Detalles de cobertura</div>
                </div>
                <div className="card-body">
                    <div className="card-text">
                        <div className="d-flex space-between"><p className="font-weight-bold">Nombre del producto: </p><p>{product.product}</p></div>
                        <div className="d-flex space-between"><p className="font-weight-bold">Cantidad de pasajeros: </p><p>{searchParams[AGES_PARAM_INDEX].split(',').length}</p></div>
                        <div className="d-flex space-between"><p className="font-weight-bold">Vigencia: </p><p>Del {moment(searchParams[START_DATE_PARAM_INDEX]).format("DD-MM-YYYY")} al {moment(searchParams[END_DATE_PARAM_INDEX]).format('DD-MM-YYYY')}</p></div>
                        <div className="d-flex space-between"><p className="font-weight-bold">Origen:</p><p>{searchParams[ORIGIN_PARAM_INDEX]}</p></div>
                        <div className="d-flex space-between"><p className="font-weight-bold">Destino: </p><p>{enginesUtils.getDestinationValueByDestinationIndex(searchParams[DESTINATION_INDEX_PARAM_INDEX])}</p></div>
                    </div>
                    <div>
                        <i className='fas fa-eye icon-to-modal'></i>
                        <a className="btn button-to-modal"
                            onClick={() => {
                                this.openModal(product.idProduct);
                            }}
                        >
                            Ver más detalles de la cobertura</a>
                    </div>
                    <div className="mt-3">
                        <InsuranceModal
                            product={product}
                            selectedModal={this.state.selectedModal}
                            closeModal={this.closeModal}
                            onSubmit={() => { console.log('') }}
                            isFromReservation={true}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({  bookFormReducer}) => {
    return {
        bookFormReducer
    }
}

export default connect(mapStateToProps, null)(InsuranceDetails);