//import logo from './logo.svg';
//import './App.css';
import React from "react";
import axios from 'axios';

import { Component } from "react";
import Footer from "./components/commons/Footer";
import Navbar from "./components/commons/Navbar";
import InsurancesEngine from "./components/engines/InsurancesEngine";

class Home extends Component {
  
  render() {
    return (
      <div className="container-full">
        <div className="text-center w-auto pt-5 pb-5 d-flex"
          style={{background: `url("https://costamar.com.pe/images/channels/Familia2Oscuro.jpg")`, height: '500px', backgroundSize: "cover"}}>
          <InsurancesEngine 
            isRequote={false}
          />
        </div>
        <Footer />
      </div>
    );
  }
   
}

export default Home;
