import React, { Component } from "react";

import { fillCommissionAgency } from '../../actions/bookFormActions';
import { connect } from "react-redux";
import enginesUtils from "../../utils/enginesUtils";
import profiles from "../../constants/profilesConstants";

class PriceInformation extends Component {

    renderToB2BOrB2C(total) {
        const rateTotalByPassenger = parseFloat(enginesUtils.getAmountPricePerPassenger(this.props.insurance, profiles.profilesToShowPassengerTotalPrice, 
                                        this.props.insurance.product, this.props.searchParams)).toFixed(2)
        return (
            <div className="details-fare">
                <div className="item-fare-price-information mb-3" style={{ fontSize: "1rem", fontWeight: "500" }}>
                    <span className="item-fare-description fare-price-text">Tarifa total por pasajero</span>
                    <span className="item-fare-price">USD {rateTotalByPassenger}</span>
                </div>
                <div className="item-fare-price-information">
                    <span className="item-fare-description" style={{ fontWeight: "600" }}>Pasajeros ( {this.props.insurance.passengersQuantity} )</span>
                    <span className="item-fare-price">USD {rateTotalByPassenger * this.props.insurance.passengersQuantity }</span>
                </div>
                {this.props.insurance.product.fareProduct && this.props.insurance.product.fareProduct.fee
                    && profiles.profilesToShowComissionAgency.includes(this.props.insurance.pos.profileId) ?
                    <div className="mt-3">
                        <div className="item-fare">
                            <span className="item-fare-description">Comisión agencia</span>
                            <span className="item-fare-price">USD {parseFloat(this.props.insurance.product.fareProduct.fee.comAgencyApply).toFixed(2)}</span>
                        </div>
                    </div> : null
                }
                <div className="item-fare-price-information mt-3 border-top mb-0" style={{ fontSize: "1.5rem", fontWeight: "500" }}>
                    <span className="item-fare-description">Total a pagar</span>
                    <span className="item-fare-price">USD {parseFloat(total).toFixed(2)}</span>
                </div>
            </div>
        )
    }

    renderWithuOutB2BorB2C(total, totalFees) {
        return (
            <div className="details-fare">
                <div className="item-fare-price-information mb-3" style={{ fontSize: "1rem", fontWeight: "500" }}>
                    <span className="item-fare-description fare-price-text">Tarifa neta por pasajero</span>
                    <span className="item-fare-price">USD {parseFloat(this.props.insurance.product.fareProduct.baseFareByPax).toFixed(2)}</span>
                </div>
                <div className="item-fare-price-information">
                    <span className="item-fare-description" style={{ fontWeight: "600" }}>Pasajeros ( {this.props.insurance.passengersQuantity} )</span>
                    <span className="item-fare-price">USD {parseFloat(this.props.insurance.product.fareProduct.baseFare).toFixed(2)}</span>
                </div>
                <div className="item-fare-price-information">
                    <span className="item-fare-description" style={{ fontWeight: "600" }}>Impuestos, cargos y tasas</span>
                    <span className="item-fare-price">USD {parseFloat(totalFees).toFixed(2)}</span>
                </div>
                <div className="item-fare-price-information">
                    <span className="item-fare-description" style={{ fontWeight: "600" }}>Cargos adicionales</span>
                    <span className="item-fare-price">USD {parseFloat(this.props.bookFormReducer.commissionAgency).toFixed(2)}</span>
                </div>
                {this.props.insurance.product.fareProduct && this.props.insurance.product.fareProduct.fee
                    && profiles.profilesToShowComissionAgency.includes(this.props.insurance.pos.profileId) ?
                    <div className="mt-3">
                        <div className="item-fare">
                            <span className="item-fare-description">Comisión agencia</span>
                            <span className="item-fare-price">USD {parseFloat(this.props.insurance.product.fareProduct.fee.comAgencyApply).toFixed(2)}</span>
                        </div>
                    </div> : null
                }
                <div className="item-fare-price-information mt-3 border-top mb-0" style={{ fontSize: "1.5rem", fontWeight: "500" }}>
                    <span className="item-fare-description">Total a pagar</span>
                    <span className="item-fare-price">USD {parseFloat(total).toFixed(2)}</span>
                </div>
            </div>
        )
    }

    render() {
        const total = parseFloat(this.props.insurance.product.fareProduct.totalToPaid) + parseFloat(this.props.bookFormReducer.commissionAgency); 
        let totalFees = 0;
        if (this.props.insurance.product.fareProduct.fee) {
            totalFees = totalFees + parseFloat(parseFloat(this.props.insurance.product.fareProduct.fee.feeCostamarApply).toFixed(2));
    
        }
        
        return (
            <div className="card mt-5">
                <div className="card-body">
                    <div className="mb-0 mt-0 InsurancesPricingInfo-container details-fare">
                        {profiles.profilesToShowPriceDetails.includes(this.props.insurance) ? this.renderWithuOutB2BorB2C(total, totalFees) : this.renderToB2BOrB2C(total)}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ bookFormReducer }) => {
    return {
        bookFormReducer
    }
}

export default connect(mapStateToProps, { fillCommissionAgency })(PriceInformation);