import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import checkIcon from '../../assets/images/icons/check.svg';
import engineUtils from "../../utils/enginesUtils";

class InsuranceModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen : props.selectedModal.openModal,
            isLoading: false
        }
    }

    handleClose = () => {
        //this.setState({ isOpen: false })
        this.props.closeModal();
    }

    onSubmit = () => {
        this.setState({
            isLoading: true
        }, () => { 
            this.props.onSubmit(false)
         })
    }

    render() {
        const { product } = this.props;
        return (
            <Modal show={this.props.selectedModal.openModal} onHide={this.handleClose}  backdrop="static"
            keyboard={false} scrollable={true}>
                <Modal.Header closeButton>
                    <Modal.Title>{product.product}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="table-responsive">
                        <table className="table dt-responsive">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th className="font-weight">Cobertura</th>
                                    <th>Valor</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    product.coverageItems.map((item,i) => {
                                        return (
                                            <tr key={i}>
                                                <img src={checkIcon} />
                                                <th className="small">{item.description.slice(4)}</th>
                                                {
                                                        item.amount && item.unidad && item.valor === null ? (
                                                            <th className="small">
                                                                {item.unidad} {engineUtils.formatThousandAmount(item.amount)}
                                                            </th>
                                                        ) : (
                                                            item.amount === null && item.unidad === null && item.valor ? (
                                                                <th className="small">
                                                                    {item.valor}
                                                                </th>
                                                            ) : null
                                                        )
                                                    }
                                            </tr>
                                        )
                                    })

                                }
                            </tbody>
                        </table>
                    </div>


                </Modal.Body>
                <Modal.Footer>
                    <span className='price-amount'>{'USD ' + parseFloat(product.fareProduct.totalFare).toFixed(2)}</span>
                    {this.props.isFromReservation ? null :
                     <Button variant="primary" onClick={this.onSubmit}>
                        {this.state.isLoading ? 
                        <div className="spinner-border spinner-border-insurance ms-auto" role="status" aria-hidden="true"></div>
                        : "Seleccionar"}
                    </Button>}
                </Modal.Footer>
            </Modal>
        )
    }
}

export default InsuranceModal;