import React, { Component } from "react";
import '../../../assets/stylesheets/Booking.css';

class PaymentFrame extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTest : true
    }
  }
  
  getPaymentProvider(paymentProvider) {
    if (paymentProvider === "102") {
      return "pagoefectivo";
    } else if (paymentProvider === "105" || paymentProvider === "106") {
      return "safetypay";
    } else if(paymentProvider === "124") {
      return "cuotealo"
    } else if(paymentProvider === "125"){
      return "acuotaz"
    }
    return "";
  }

  render() {
    const {paymentProvider, registerRS } = this.props;
    return (
      <div className="PaymentFrame">
        {
          registerRS && registerRS.paymentUrl ?
            <iframe
              className={this.getPaymentProvider(paymentProvider)}
              src={registerRS.paymentUrl}
            ></iframe> : null
        }
      </div>
    )
  }
}

export default PaymentFrame;
