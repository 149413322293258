export const B2B_PERU_AGENTES_VACACIONES = '5d4aff75dfbd5948a0c01700';
export const B2B_PERU_AGENTES_CONSOLIDACION = '634ecfccdfbd597017d61d65';
export const B2B_MAYORISTA_CTM_PERU = '668875c1dfbd5930dec37d9f';
export const B2B_PERU = '5b15530ba6c0f49a19e10147';
export const B2C_PERU = '5b11c2aea6c0f432e180f2ec';

const profiles = { 
    profilesToShowAccountId : [B2B_PERU_AGENTES_VACACIONES, B2B_PERU_AGENTES_CONSOLIDACION, B2B_MAYORISTA_CTM_PERU],
    profilesToShowPassengerTotalPrice : [B2B_PERU, B2C_PERU],
    profilesToShowPriceDetails: [B2B_PERU_AGENTES_VACACIONES, B2B_PERU_AGENTES_CONSOLIDACION, B2B_MAYORISTA_CTM_PERU],
    profilesToShowComissionAgency: [B2B_PERU_AGENTES_CONSOLIDACION, B2B_MAYORISTA_CTM_PERU, B2B_PERU]
};

export default profiles;