import axios from "axios";
import { LOAD_ENGINE } from "./types";

import {
    BASE_URL
  } from "../config/keys";

const requestConfig = {
    withCredentials: true
}

export const loadEngine = ({
    engineId
}) => async dispatch => {
    try {
        const res = await axios.get(`${BASE_URL}/api/engines/${engineId}`, requestConfig);
        dispatch({
            type: LOAD_ENGINE,
            payload: res.data
        })
    } catch (error) {
    }
    
    /*dispatch({
        type: LOAD_ENGINE,
        payload: res.data
    });*/
}