import React, { Component } from "react";
import { connect } from "react-redux";
import { fillContactForm } from "../../actions/bookFormActions";
import validator from '../../utils/validator';

class ContactForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            firstName: "",
            lastName: "",
            phoneNumber: "",
            validates: {},
            isValid: false,
            completedFields: [],
            isChecked: false,
            email: ""
        }
    }

    componentWillReceiveProps(nextProps) {
        /*if (nextProps.handleValidation && !this.state.isValid) {//handleValidation comentado de forma temporal
          this.validate();
        }*/
        if (!this.state.isValid) {
            this.validate();
        }

        /*if (nextProps.engine && nextProps.engine.profile) {
          if (nextProps.engine.profile.countryCode === "PE") {
            this.props.fillContactForm({ countryCode: "PE+51" });
          } else if (nextProps.engine.profile.countryCode === "US") {
            this.props.fillContactForm({ countryCode: "US+1" });
          }
        }*/
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.isValid && this.state === nextState) {
            return false;
        }

        return true;
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.isValid === true) {
            this.props.fillContactForm({
                contact: Object.assign(this.state, {})
            });
        }
    }

    isFormValid() {
        let validates = {};

        for (let field in this.state) {
            const { message } = this.validateField(field);
            if (typeof message !== "undefined") validates[field] = { message };
        }

        return Object.keys(validates).length === 0;
    }

    validate(field = null) {
        let validates = {};
        let isValid = false;

        if (!field) {
            for (let prop in this.state) {
                const { message } = this.validateField(prop);
                if (typeof message !== "undefined") validates[prop] = { message };
            }

            isValid = Object.keys(validates).length === 0;
        } else {
            const { message } = this.validateField(field);
            if (typeof message !== "undefined") validates[field] = { message };

            isValid = this.isFormValid();
        }

        this.setState({ validates, isValid });
    }

    validate2 = (field = null) => {
        let invalidFields = {};
        let completedFields = this.state.completedFields;

        if (!field) {
        } else {
            let fieldToValidate = field;
            const { message } = this.validateField(fieldToValidate);
            if (typeof message !== 'undefined') { //hay error
                invalidFields[fieldToValidate] = { message };
            } else {
                if (completedFields.indexOf(fieldToValidate) === -1) {
                    completedFields.push(fieldToValidate);
                }
            }

            let isValid = false;
            const requiredFields = this.getRequiredFields();

            if (Object.keys(invalidFields).length === 0) {//no hay campos inválidos, debemos evaluar que 
                //la cantidad de campos completos coincidan con la cantidad de campos requeridos
                isValid = this.isFieldsCompleted(requiredFields, completedFields);
            }

            this.setState({ invalidFields, completedFields, isValid });
        }

    }

    isFieldsCompleted(requiredFields, completedFields) {
        return (
            requiredFields.length <= completedFields.length &&
            requiredFields.every(function (v) {
                return completedFields.indexOf(v) !== -1;
            })
        );
    }

    validateField(field) {
        let error = {};
        let validation;

        switch (field) {
            case 'firstName':
                validation = validator.isPresent(this.state.firstName);
                if (!validation.isValid) {
                    error = validation.error;
                }
                break;
            case 'lastName':
                validation = validator.isPresent(this.state.lastName);
                if (!validation.isValid) {
                    error = validation.error;
                }
                break;
            case 'phoneNumber':
                validation = validator.isPresent(this.state.phoneNumber);
                if (!validation.isValid) {
                    error = validation.error;
                }
                break;
            case 'email':
                validation = validator.isPresent(this.state.email);
                if (!validation.isValid) {
                    error = validation.error;
                }
                break;
            default:
                break;
        }
        return error;
    }

    getRequiredFields() {
        const defaultRequiredFields = [
            "firstName",
            "lastName",
            "phoneNumber"
        ]
        return defaultRequiredFields;
    }

    onChange = (e) => {
        const field = e.target.name;
        const value = e.target.value;
        this.setState({ [field]: value }, () =>
            this.validate(field)
        )
    }

    render() {
        return (
            <div className="ContactForm">
                <h1 className="font-secondary text-2xl font-medium mb-3">
                    ¿Cómo nos contactamos con usted?
                </h1>
                <div className={`bg-white border rounded-sm p-3 needs-validation`}>
                    <div className="row mb-3">
                        <div className="col">
                            <div className="checkbox">
                                <label className="control control--checkbox" style={{ marginRight: "10px" }}>
                                    Usar datos del primer pasajero
                                </label>
                                <input
                                    type="checkbox"
                                    defaultChecked={this.state.isChecked}
                                    className="cursor-pointer"
                                    onChange={e => {
                                        const checked = e.target.checked;
                                        if (this.props.bookForm.passengers.length > 0) {
                                            const { firstName, lastName, phoneNumber, email } = this.props.bookForm.passengers[0];
                                            this.setState({
                                                checked: checked,
                                                firstName: firstName,
                                                lastName: lastName,
                                                phoneNumber: phoneNumber,
                                                email: email
                                            }, () => {
                                                this.props.fillContactForm({
                                                    firstName,
                                                    lastName,
                                                    phoneNumber,
                                                    email
                                                });
                                            })
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="inputEmail4" className="mb-2">Nombre</label>
                            <input
                                name='firstName'
                                onChange={this.onChange}
                                className="form-control"
                                //placeholder='Ingrese su nombre'
                                value={this.state.firstName}
                                required
                            />
                            <div className="valid-feedback">
                                Nombre ingresado correctamente
                            </div>
                            <div className="invalid-feedback">
                                Por favor, completa el nombre
                            </div>
                        </div>
                        <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="inputPassword4" className="mb-2">Apellidos</label>
                            <input
                                name='lastName'
                                onChange={this.onChange}
                                className="form-control"
                                //placeholder='Ingrese su nombre'
                                value={this.state.lastName}
                                required
                            />
                            <div className="valid-feedback">
                                Apellido ingresado correctamente
                            </div>
                            <div className="invalid-feedback">
                                Por favor, completa el apellido
                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <label htmlFor="inputPassword4" className="mb-2">Teléfono</label>
                            <input
                                name='phoneNumber'
                                onChange={this.onChange}
                                className="form-control"
                                //placeholder='Ingrese su nombre'
                                value={this.state.phoneNumber}
                                required
                            />
                            <div className="valid-feedback">
                                Número de teléfono ingresado correctamente
                            </div>
                            <div className="invalid-feedback">
                                Por favor, completa el número de teléfono
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <label htmlFor="inputEmail" className="mb-2">Correo</label>
                            <input
                                name='email'
                                onChange={this.onChange}
                                className="form-control"
                                //placeholder='Ingrese su nombre'
                                value={this.state.email}
                                required
                            />
                            <div className="valid-feedback">
                                Correo ingresado correctamente
                            </div>
                            <div className="invalid-feedback">
                                Por favor, completa el correo
                            </div>
                        </div>
                    </div>
                </div>
                <input type="hidden" value={this.state.isValid} id="ct_f" />
            </div>
        )
    }


}

function mapStateToProps({ bookFormReducer }) {
    return {
        bookForm: bookFormReducer
    };
}

export default connect(mapStateToProps, { fillContactForm })(ContactForm);